import {
  type AvatarComponent,
  ConnectButton as RainbowButton,
  RainbowKitProvider,
  darkTheme,
} from '@rainbow-me/rainbowkit';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import classNames from 'classnames';
import { type Address } from 'viem';
import { WagmiProvider, useAccount, type Config } from 'wagmi';
import { useMobile } from '@/shared/hooks';
import { baseStyles, disabledStyles, hoverStyles } from './flip-ui-kit/Button';
import { queryCacheConfig } from '../graphql/client';
import useTracking from '../hooks/useTracking';
import { SharedEvents, type SharedTrackEvents } from '../types';
import { getEthereumChain } from '../utils';

const generateGradient = (address: Address) => {
  let hue1 = 0;
  try {
    hue1 = Number(BigInt(address) % 360n);
  } catch {
    // pass
  }
  const hue2 = (hue1 + 180) % 360;
  const saturation = 75;
  const lightness = 50;
  const color = `hsl(${hue1} ${saturation}% ${lightness}%)`;
  const color2 = `hsl(${hue2} ${saturation}% ${lightness}%)`;
  return `linear-gradient(135deg, ${color} 6.7%, ${color2} 95%)`;
};

export const ConnectAvatar: AvatarComponent = ({ ensImage, size, address }) => {
  if (ensImage) {
    return <img alt="Avatar" src={ensImage} width={size} height={size} className="rounded-full" />;
  }

  const background = generateGradient(address as Address);

  return <div className="rounded-full" style={{ background, width: size, height: size }} />;
};

type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & { connected?: boolean };

const Button = ({ className, children, connected = false, ...rest }: ButtonProps) => (
  <button
    {...rest}
    className={classNames(
      className,
      'h-10 min-w-[130px] rounded-md px-4 text-14 transition duration-300',
      'hover:cursor-pointer hover:disabled:cursor-default',
      'font-aeonikMedium font-medium outline-none',
      !connected && baseStyles['primary-standard'],
      !connected && hoverStyles['primary-standard'],
      !connected && disabledStyles['primary-standard'],
      connected && 'border border-cf-gray-4 bg-black text-cf-light-3',
      connected && 'hover:bg-cf-gray-2 hover:text-cf-white',
    )}
    type="button"
  >
    {children}
  </button>
);

const queryClient = new QueryClient({
  queryCache: new QueryCache(queryCacheConfig),
});

export const ConnectButton = () => {
  const track = useTracking<SharedTrackEvents>();
  const { connector } = useAccount();
  const isMobile = useMobile();

  return (
    <RainbowButton.Custom>
      {({ account, chain, openChainModal, openConnectModal, mounted, openAccountModal }) => {
        if (!mounted) return false; // do not render button on server

        let onClick;
        let content;

        if (!account) {
          onClick = openConnectModal;
          content = 'Connect Wallet';
        } else if (chain?.unsupported) {
          onClick = openChainModal;
          content = <span className="text-12 lg:text-14">Unsupported network</span>;
        } else {
          track(SharedEvents.ConnectWallet, {
            props: {
              connectedWallet: account.address,
              walletProvider: connector?.name ?? '',
              path: window.location.pathname,
            },
          });
          onClick = openAccountModal;
          content = (
            <div className="flex items-center justify-center space-x-2">
              {chain?.hasIcon && (
                <div
                  className="h-4 w-4 overflow-hidden rounded-full"
                  style={{ background: chain.iconBackground }}
                >
                  {chain.iconUrl && (
                    <img
                      alt={chain.name ?? 'Chain icon'}
                      src={chain.iconUrl}
                      style={{ width: 16, height: 16 }}
                    />
                  )}
                </div>
              )}
              <span>{account.ensName ?? account.displayName}</span>
              {!isMobile && (
                <ConnectAvatar size={16} address={account.address} ensImage={account.ensAvatar} />
              )}
            </div>
          );
        }

        return (
          <Button onClick={onClick} connected={Boolean(account)}>
            {content}
          </Button>
        );
      }}
    </RainbowButton.Custom>
  );
};

export const Provider = ({ children, config }: { children: React.ReactNode; config: Config }) => (
  <WagmiProvider config={config}>
    <QueryClientProvider client={queryClient}>
      <RainbowKitProvider
        theme={darkTheme({
          borderRadius: 'small',
          accentColor: '#46DA93',
          accentColorForeground: 'black',
        })}
        avatar={ConnectAvatar}
        initialChain={getEthereumChain().wagmiChain}
      >
        {children}
      </RainbowKitProvider>
    </QueryClientProvider>
  </WagmiProvider>
);
