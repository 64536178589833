import { type SVGProps } from 'react';

export const BtcLogo = (props?: SVGProps<SVGSVGElement>) => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="34" height="34" rx="17" fill="#F7931A" />
    <rect
      width="34"
      height="34"
      rx="17"
      fill="url(#paint0_radial_1286_125068)"
      fillOpacity="0.15"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.4128 15.1154C24.73 12.9912 23.1127 11.8493 20.9012 11.0875L21.6186 8.21001L19.8666 7.7735L19.1682 10.5751C18.7082 10.4604 18.2353 10.3522 17.7653 10.2449L18.4687 7.42479L16.7182 6.98828L16.0003 9.86476C15.6192 9.77795 15.2451 9.69215 14.8819 9.60185L14.8839 9.59287L12.4684 8.98974L12.0024 10.8605C12.0024 10.8605 13.302 11.1583 13.2745 11.1768C13.9839 11.3539 14.1126 11.8233 14.0907 12.1955L13.2735 15.4735C13.3224 15.486 13.3858 15.504 13.4556 15.5319L13.271 15.486L12.1251 20.0781C12.0383 20.2936 11.8183 20.6169 11.3225 20.4942C11.3399 20.5196 10.0493 20.1764 10.0493 20.1764L9.17981 22.1818L11.4596 22.75C11.717 22.8145 11.9712 22.8808 12.2226 22.9464L12.2234 22.9466C12.386 22.9891 12.5474 23.0312 12.7078 23.0723L11.983 25.9832L13.7325 26.4197L14.4509 23.5402C14.9283 23.67 15.3922 23.7897 15.8462 23.9024L15.1308 26.7684L16.8823 27.2049L17.6072 24.3C20.5939 24.8652 22.8403 24.6373 23.7847 21.9364C24.5465 19.7613 23.7473 18.5067 22.1758 17.688C23.3202 17.4231 24.1823 16.6703 24.4123 15.1154H24.4128ZM20.4101 20.7275C19.9128 22.7238 16.7874 21.8978 15.3587 21.5202C15.2308 21.4864 15.1164 21.4562 15.0188 21.4319L15.9806 17.5762C16.0999 17.606 16.2457 17.6387 16.4109 17.6757L16.4109 17.6757C17.889 18.0074 20.9181 18.6871 20.4106 20.7275H20.4101ZM16.7079 15.8869C17.9 16.2049 20.4986 16.8982 20.9513 15.0847C21.4141 13.2294 18.8872 12.6702 17.6532 12.3971C17.5144 12.3664 17.392 12.3394 17.2921 12.3145L16.42 15.8115C16.5026 15.8321 16.5994 15.8579 16.7079 15.8869Z"
      fill="white"
    />
    <defs>
      <radialGradient
        id="paint0_radial_1286_125068"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17) rotate(90) scale(21.7411)"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);

export const DotLogo = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="34" height="34" rx="17" fill="#E82A8F" />
    <rect
      width="34"
      height="34"
      rx="17"
      fill="url(#paint0_radial_1286_125052)"
      fillOpacity="0.15"
    />
    <path
      d="M16.9992 11.194C18.9251 11.194 20.4864 10.2548 20.4864 9.09626C20.4864 7.93772 18.9251 6.99854 16.9992 6.99854C15.0733 6.99854 13.5121 7.93772 13.5121 9.09626C13.5121 10.2548 15.0733 11.194 16.9992 11.194Z"
      fill="white"
    />
    <path
      d="M16.9992 27.0001C18.9251 27.0001 20.4864 26.061 20.4864 24.9024C20.4864 23.7439 18.9251 22.8047 16.9992 22.8047C15.0733 22.8047 13.5121 23.7439 13.5121 24.9024C13.5121 26.061 15.0733 27.0001 16.9992 27.0001Z"
      fill="white"
    />
    <path
      d="M12.1396 14.0977C13.1026 12.3726 13.0963 10.5043 12.1257 9.92459C11.155 9.34493 9.5875 10.2735 8.62455 11.9985C7.6616 13.7236 7.66785 15.592 8.63851 16.1716C9.60917 16.7513 11.1767 15.8228 12.1396 14.0977Z"
      fill="white"
    />
    <path
      d="M25.3724 21.9996C26.3353 20.2745 26.3296 18.4065 25.3596 17.8272C24.3896 17.2479 22.8226 18.1768 21.8597 19.9019C20.8967 21.6269 20.9024 23.495 21.8724 24.0743C22.8424 24.6535 24.4094 23.7247 25.3724 21.9996Z"
      fill="white"
    />
    <path
      d="M12.1261 24.0739C13.0968 23.4943 13.1031 21.6259 12.1401 19.9008C11.1772 18.1758 9.60966 17.2472 8.639 17.8269C7.66834 18.4066 7.66209 20.2749 8.62504 22C9.58799 23.7251 11.1555 24.6536 12.1261 24.0739Z"
      fill="white"
    />
    <path
      d="M25.3604 16.1723C26.3304 15.593 26.3361 13.725 25.3732 11.9999C24.4102 10.2748 22.8433 9.34598 21.8733 9.92526C20.9033 10.5045 20.8976 12.3726 21.8605 14.0977C22.8235 15.8227 24.3904 16.7516 25.3604 16.1723Z"
      fill="white"
    />
    <defs>
      <radialGradient
        id="paint0_radial_1286_125052"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17) rotate(90) scale(21.7411)"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);

export const EthLogo = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="34" height="34" rx="17" fill="white" />
    <path
      d="M17.0998 7L16.9666 7.45284V20.5932L17.0998 20.7262L23.1994 17.1207L17.0998 7Z"
      fill="#333333"
    />
    <path d="M17.1001 7L11.0005 17.1207L17.1001 20.7262V14.3483V7Z" fill="#8C8C8C" />
    <path
      d="M17.1002 22.7106L17.0251 22.8021V27.483L17.1002 27.7022L23.2035 19.1069L17.1002 22.7106Z"
      fill="#333333"
    />
    <path d="M17.0996 27.7023V22.7106L11 19.1069L17.0996 27.7023Z" fill="#8C8C8C" />
    <path d="M17.0997 20.7265L23.1992 17.1211L17.0997 14.3486V20.7265Z" fill="black" />
    <path d="M11 17.1211L17.0995 20.7265V14.3486L11 17.1211Z" fill="#383838" />
  </svg>
);

export const FlipLogo = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="34" height="34" rx="17" fill="#161616" />
    <rect
      width="34"
      height="34"
      rx="17"
      fill="url(#paint0_radial_1286_125091)"
      fillOpacity="0.15"
    />
    <rect
      x="0.25"
      y="0.25"
      width="33.5"
      height="33.5"
      rx="16.75"
      stroke="url(#paint1_linear_1286_125091)"
      strokeOpacity="0.1"
      strokeWidth="0.5"
    />
    <path
      d="M25.3181 9V15.952C25.3181 17.1879 24.927 18.3852 24.2212 19.3701C24.0018 19.6791 23.7538 19.9591 23.4772 20.2198L23.4582 20.2294H20.1962V13.7892H17.1726H14.1204H13.8534H10.8394L10.8775 13.7505L13.4337 11.3463L14.378 10.4676C15.4462 9.46347 16.7625 9 18.2122 9H25.3181Z"
      fill="url(#paint2_linear_1286_125091)"
    />
    <path
      d="M23.4583 20.2398L23.4488 20.2495L23.4392 20.2592L23.2676 20.4233L22.619 21.0413L20.2631 23.2524L20.0342 23.4648C18.9659 24.4593 17.5638 25 16.1236 25H8.98914V21.8909L8.99867 20.2302L9.01775 18.0191C9.02729 16.4066 9.68541 14.881 10.8491 13.7803H13.8631H13.8726V20.2302H23.4583V20.2398Z"
      fill="url(#paint3_linear_1286_125091)"
    />
    <defs>
      <radialGradient
        id="paint0_radial_1286_125091"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17) rotate(90) scale(21.7411)"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint1_linear_1286_125091"
        x1="4.5"
        y1="5.5"
        x2="29.5"
        y2="30.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.333333" stopColor="white" stopOpacity="0" />
        <stop offset="0.666667" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1286_125091"
        x1="18.4212"
        y1="8.31418"
        x2="17.4851"
        y2="25.9584"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#48EF8A" />
        <stop offset="0.5458" stopColor="#43B4A6" />
        <stop offset="0.6918" stopColor="#2D796F" />
        <stop offset="0.9662" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1286_125091"
        x1="16.6544"
        y1="25.8753"
        x2="14.197"
        y2="7.79539"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF5F96" />
        <stop offset="0.6103" stopColor="#FF33AF" />
        <stop offset="1" />
      </linearGradient>
    </defs>
  </svg>
);

export const MaticLogo = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_i_1286_125076)">
      <rect width="34" height="34" rx="17" fill="#8247E5" />
      <rect
        width="34"
        height="34"
        rx="17"
        fill="url(#paint0_radial_1286_125076)"
        fillOpacity="0.15"
      />
    </g>
    <path
      d="M21.7844 13.8958C21.4516 13.7057 21.0238 13.7057 20.6436 13.8958L17.9818 15.4644L16.1755 16.4626L13.5613 18.0311C13.2285 18.2213 12.8008 18.2213 12.4205 18.0311L10.3766 16.7953C10.0439 16.6052 9.80623 16.2249 9.80623 15.7971V13.4205C9.80623 13.0402 9.99635 12.66 10.3766 12.4223L12.4205 11.234C12.7532 11.0439 13.181 11.0439 13.5613 11.234L15.6052 12.4699C15.9379 12.66 16.1755 13.0402 16.1755 13.468V15.0366L17.9818 13.9909V12.3748C17.9818 11.9945 17.7916 11.6143 17.4114 11.3766L13.6088 9.1426C13.2761 8.95247 12.8483 8.95247 12.468 9.1426L8.57039 11.4241C8.19013 11.6143 8 11.9945 8 12.3748V16.8428C8 17.2231 8.19013 17.6033 8.57039 17.841L12.4205 20.075C12.7532 20.2651 13.181 20.2651 13.5613 20.075L16.1755 18.554L17.9818 17.5083L20.596 15.9872C20.9288 15.7971 21.3566 15.7971 21.7368 15.9872L23.7807 17.1755C24.1134 17.3657 24.3511 17.7459 24.3511 18.1737V20.5503C24.3511 20.9306 24.161 21.3109 23.7807 21.5485L21.7844 22.7368C21.4516 22.927 21.0238 22.927 20.6436 22.7368L18.5997 21.5485C18.267 21.3584 18.0293 20.9781 18.0293 20.5503V19.0293L16.2231 20.075V21.6436C16.2231 22.0238 16.4132 22.4041 16.7935 22.6418L20.6436 24.8758C20.9763 25.0659 21.4041 25.0659 21.7844 24.8758L25.6345 22.6418C25.9672 22.4516 26.2049 22.0714 26.2049 21.6436V17.128C26.2049 16.7478 26.0147 16.3675 25.6345 16.1298L21.7844 13.8958Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_i_1286_125076"
        x="0"
        y="0"
        width="36.0146"
        height="36.0146"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2.01459" dy="2.01459" />
        <feGaussianBlur stdDeviation="6.54743" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1286_125076" />
      </filter>
      <radialGradient
        id="paint0_radial_1286_125076"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17) rotate(90) scale(21.7411)"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);

export const UsdcLogo = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="34"
    height="35"
    viewBox="0 0 34 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1286_125062)">
      <rect y="0.931152" width="34" height="34" rx="17" fill="#2769BE" />
      <rect
        y="0.931152"
        width="34"
        height="34"
        rx="17"
        fill="url(#paint0_radial_1286_125062)"
        fillOpacity="0.12"
      />
      <g filter="url(#filter0_d_1286_125062)">
        <path
          d="M20.996 20.0946C20.996 17.9742 19.7123 17.2472 17.1451 16.9444C15.3113 16.702 14.9445 16.2174 14.9445 15.3691C14.9445 14.5208 15.5558 13.9757 16.7783 13.9757C17.8786 13.9757 18.4899 14.3392 18.7955 15.248C18.8566 15.4297 19.04 15.5508 19.2234 15.5508H20.2013C20.4459 15.5508 20.6292 15.3691 20.6292 15.1268V15.0662C20.3847 13.7333 19.2844 12.7034 17.8786 12.5823V11.1283C17.8786 10.8859 17.6952 10.7042 17.3896 10.6436H16.4727C16.2282 10.6436 16.0448 10.8253 15.9836 11.1283V12.5217C14.1498 12.7641 12.9886 13.9757 12.9886 15.4903C12.9886 17.4896 14.211 18.2771 16.7783 18.5801C18.4899 18.883 19.04 19.2465 19.04 20.2159C19.04 21.1853 18.1842 21.8516 17.0229 21.8516C15.4335 21.8516 14.8834 21.1851 14.7 20.2764C14.639 20.0341 14.4556 19.9129 14.2722 19.9129H13.233C12.9886 19.9129 12.8052 20.0946 12.8052 20.337V20.3976C13.0496 21.9121 14.0276 23.0026 16.0448 23.3056V24.7597C16.0448 25.0019 16.2282 25.1836 16.5338 25.2443H17.4506C17.6952 25.2443 17.8786 25.0625 17.9397 24.7597V23.3056C19.7735 23.0026 20.996 21.7304 20.996 20.0946Z"
          fill="url(#paint1_linear_1286_125062)"
        />
      </g>
      <g filter="url(#filter1_d_1286_125062)">
        <path
          d="M13.9024 26.3938C9.13459 24.6975 6.68949 19.4268 8.46225 14.7617C9.37915 12.2172 11.3963 10.2785 13.9024 9.36979C14.147 9.24867 14.2692 9.06692 14.2692 8.7639V7.91577C14.2692 7.67339 14.147 7.49164 13.9024 7.43115C13.8413 7.43115 13.7191 7.43115 13.6579 7.49164C7.85094 9.30915 4.67232 15.4282 6.50611 21.1836C7.60639 24.5763 10.2349 27.1814 13.6579 28.2719C13.9024 28.3931 14.147 28.2719 14.208 28.0296C14.2692 27.9691 14.2692 27.9084 14.2692 27.7873V26.9391C14.2692 26.7573 14.0858 26.5151 13.9024 26.3938ZM20.3819 7.49164C20.1373 7.37052 19.8928 7.49164 19.8318 7.73402C19.7706 7.79466 19.7706 7.85514 19.7706 7.97641V8.82453C19.7706 9.06692 19.954 9.30915 20.1373 9.43042C24.9052 11.1267 27.3503 16.3975 25.5775 21.0625C24.6606 23.607 22.6435 25.5457 20.1373 26.4544C19.8928 26.5756 19.7706 26.7573 19.7706 27.0603V27.9084C19.7706 28.1508 19.8928 28.3326 20.1373 28.3931C20.1985 28.3931 20.3207 28.3931 20.3819 28.3326C26.1888 26.5151 29.3675 20.396 27.5337 14.6406C26.4334 11.1873 23.7437 8.58215 20.3819 7.49164Z"
          fill="url(#paint2_linear_1286_125062)"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_1286_125062"
        x="8.72334"
        y="7.20963"
        width="16.3546"
        height="22.7643"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.64791" />
        <feGaussianBlur stdDeviation="2.04092" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1286_125062" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1286_125062"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_1286_125062"
        x="1.90547"
        y="3.99723"
        width="30.2286"
        height="29.1256"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.64791" />
        <feGaussianBlur stdDeviation="2.04092" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1286_125062" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1286_125062"
          result="shape"
        />
      </filter>
      <radialGradient
        id="paint0_radial_1286_125062"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17 0.931152) rotate(90) scale(21.7411)"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint1_linear_1286_125062"
        x1="16.9006"
        y1="10.6436"
        x2="22.4171"
        y2="26.1281"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D0D0D0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1286_125062"
        x1="17.0199"
        y1="7.43115"
        x2="21.611"
        y2="31.6119"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D0D0D0" />
      </linearGradient>
      <clipPath id="clip0_1286_125062">
        <rect width="34" height="34" fill="white" transform="translate(0 0.931152)" />
      </clipPath>
    </defs>
  </svg>
);

export const UsdtLogo = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_8042_322729)">
      <g filter="url(#filter0_ii_8042_322729)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.32973 4.42358L0.0264027 17.6637C0.0024167 17.713 -0.0051843 17.7686 0.00471124 17.8225C0.0146068 17.8763 0.0414797 17.9256 0.0814002 17.9631L17.1052 34.276C17.1535 34.3224 17.2179 34.3483 17.2849 34.3483C17.3519 34.3483 17.4163 34.3224 17.4647 34.276L34.4885 17.9642C34.5284 17.9267 34.5552 17.8774 34.5651 17.8235C34.575 17.7696 34.5674 17.714 34.5434 17.6647L28.2401 4.4246C28.2198 4.38013 28.1871 4.34246 28.1459 4.31609C28.1047 4.28972 28.0568 4.27577 28.0079 4.2759H6.56398C6.51484 4.27529 6.46659 4.28891 6.42502 4.31511C6.38346 4.34131 6.35036 4.37898 6.32973 4.42358Z"
          fill="url(#paint0_linear_8042_322729)"
        />
      </g>
      <path
        d="M0.552425 17.722L6.71577 4.7759H27.8536L34.0174 17.723L17.2849 33.7558L0.552425 17.722ZM28.0092 4.7759C28.0088 4.7759 28.0084 4.7759 28.0079 4.7759L28.0092 4.7759ZM34.1461 17.5998L34.1425 17.6031C34.1437 17.602 34.1449 17.6009 34.1461 17.5998ZM34.0939 17.8837C34.0933 17.8823 34.0926 17.881 34.092 17.8797L34.0939 17.8837ZM17.4512 33.9151L17.4511 33.915L17.4512 33.9151Z"
        stroke="url(#paint1_linear_8042_322729)"
        strokeOpacity="0.2"
        style={{ mixBlendMode: 'overlay' }}
      />
      <g filter="url(#filter1_d_8042_322729)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.472 19.0237C19.3498 19.0329 18.7184 19.0706 17.3098 19.0706C16.1895 19.0706 15.3941 19.0369 15.115 19.0237C10.7855 18.8332 7.55388 18.0796 7.55388 17.1772C7.55388 16.2748 10.7855 15.5222 15.115 15.3287V18.2731C15.3982 18.2935 16.2089 18.3413 17.3292 18.3413C18.6736 18.3413 19.3468 18.2853 19.468 18.2741V15.3307C23.7883 15.5232 27.0128 16.2769 27.0128 17.1772C27.0128 18.0775 23.7893 18.8312 19.468 19.0227L19.472 19.0237ZM19.472 15.0262V12.3914H25.5014V8.37354H9.08566V12.3914H15.114V15.0252C10.2141 15.2503 6.5293 16.2209 6.5293 17.384C6.5293 18.5471 10.2141 19.5166 15.114 19.7427V28.1859H19.471V19.7397C24.3597 19.5146 28.0384 18.545 28.0384 17.3829C28.0384 16.2209 24.3627 15.2513 19.471 15.0252L19.472 15.0262Z"
          fill="url(#paint2_linear_8042_322729)"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_ii_8042_322729"
        x="-1.99951"
        y="2.27588"
        width="38.5688"
        height="34.0723"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_8042_322729" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="-2" />
        <feGaussianBlur stdDeviation="3.15" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.17 0" />
        <feBlend
          mode="normal"
          in2="effect1_innerShadow_8042_322729"
          result="effect2_innerShadow_8042_322729"
        />
      </filter>
      <filter
        id="filter1_d_8042_322729"
        x="0.229297"
        y="3.07353"
        width="34.109"
        height="32.4125"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="3.15" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8042_322729" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_8042_322729"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_8042_322729"
        x1="17.2849"
        y1="4.27588"
        x2="17.2849"
        y2="34.3483"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#50AF95" />
        <stop offset="1" stopColor="#3D7364" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_8042_322729"
        x1="17.2849"
        y1="-12.7241"
        x2="17.2849"
        y2="34.3483"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" stopColor="#666666" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_8042_322729"
        x1="17.2839"
        y1="8.37354"
        x2="21.5"
        y2="31.2759"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D0D0D0" />
      </linearGradient>
      <clipPath id="clip0_8042_322729">
        <rect width="34.57" height="30.0724" fill="white" transform="translate(0 4.27588)" />
      </clipPath>
    </defs>
  </svg>
);

export const SolLogo = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2534_1238)">
      <g filter="url(#filter0_i_2534_1238)">
        <rect y="0.0649414" width="32.0688" height="32.0688" rx="16.0344" fill="#2C2D2C" />
        <rect
          y="0.0649414"
          width="32.0688"
          height="32.0688"
          rx="16.0344"
          fill="url(#paint0_radial_2534_1238)"
          fillOpacity="0.15"
        />
      </g>
      <g opacity="0.5" clipPath="url(#clip1_2534_1238)" filter="url(#filter1_f_2534_1238)">
        <path
          d="M9.90352 19.8902C10.0157 19.7781 10.1698 19.7127 10.3334 19.7127H25.1626C25.4336 19.7127 25.5691 20.0397 25.3775 20.2313L22.4481 23.1607C22.336 23.2728 22.1818 23.3382 22.0183 23.3382H7.18903C6.91805 23.3382 6.78255 23.0112 6.97411 22.8196L9.90352 19.8902Z"
          fill="url(#paint1_linear_2534_1238)"
        />
        <path
          d="M9.90352 8.95321C10.0203 8.84107 10.1745 8.77567 10.3334 8.77567H25.1626C25.4336 8.77567 25.5691 9.10271 25.3775 9.29427L22.4481 12.2237C22.336 12.3358 22.1818 12.4012 22.0183 12.4012H7.18903C6.91805 12.4012 6.78255 12.0742 6.97411 11.8826L9.90352 8.95321Z"
          fill="url(#paint2_linear_2534_1238)"
        />
        <path
          d="M22.4481 14.3862C22.336 14.2741 22.1818 14.2086 22.0183 14.2086H7.18903C6.91805 14.2086 6.78255 14.5357 6.97411 14.7273L9.90352 17.6567C10.0157 17.7688 10.1698 17.8342 10.3334 17.8342H25.1626C25.4336 17.8342 25.5691 17.5072 25.3775 17.3156L22.4481 14.3862Z"
          fill="url(#paint3_linear_2534_1238)"
        />
      </g>
      <g clipPath="url(#clip2_2534_1238)" filter="url(#filter2_f_2534_1238)">
        <path
          d="M7.51747 21.3481C7.67225 21.1933 7.88507 21.103 8.1108 21.103H28.5806C28.9547 21.103 29.1417 21.5544 28.8773 21.8189L24.8336 25.8625C24.6788 26.0173 24.466 26.1076 24.2403 26.1076H3.77047C3.39642 26.1076 3.20939 25.6561 3.47381 25.3917L7.51747 21.3481Z"
          fill="url(#paint4_linear_2534_1238)"
        />
        <path
          d="M7.51759 6.25105C7.67882 6.09627 7.89165 6.00598 8.11092 6.00598H28.5807C28.9548 6.00598 29.1418 6.45743 28.8774 6.72184L24.8337 10.7655C24.679 10.9203 24.4661 11.0106 24.2404 11.0106H3.7706C3.39654 11.0106 3.20951 10.5591 3.47393 10.2947L7.51759 6.25105Z"
          fill="url(#paint5_linear_2534_1238)"
        />
        <path
          d="M24.8336 13.7503C24.6788 13.5955 24.466 13.5052 24.2403 13.5052H3.77047C3.39642 13.5052 3.20939 13.9567 3.47381 14.2211L7.51747 18.2648C7.67225 18.4196 7.88507 18.5098 8.1108 18.5098H28.5806C28.9547 18.5098 29.1417 18.0584 28.8773 17.794L24.8336 13.7503Z"
          fill="url(#paint6_linear_2534_1238)"
        />
      </g>
      <g clipPath="url(#clip3_2534_1238)">
        <path
          d="M10.2303 20.1023C10.2866 20.0459 10.3647 20.0126 10.448 20.0126H25.2772C25.2784 20.0126 25.2792 20.0126 25.2798 20.0127C25.2802 20.0132 25.2806 20.0139 25.281 20.0149C25.2814 20.0158 25.2816 20.0166 25.2817 20.0172C25.2813 20.0177 25.2808 20.0183 25.28 20.0191L22.3506 22.9485C22.2943 23.0048 22.2162 23.0381 22.1329 23.0381H7.30365C7.30254 23.0381 7.30168 23.0381 7.30105 23.038C7.30071 23.0375 7.3003 23.0368 7.29989 23.0358C7.2995 23.0349 7.29928 23.0341 7.29917 23.0335C7.29956 23.033 7.30012 23.0324 7.30087 23.0317L7.09112 22.8219L7.30087 23.0317L10.2303 20.1023Z"
          fill="url(#paint7_linear_2534_1238)"
          stroke="url(#paint8_linear_2534_1238)"
          strokeWidth="0.6"
        />
        <path
          d="M10.0181 8.95311C10.1349 8.84098 10.2891 8.77557 10.448 8.77557H25.2772C25.5482 8.77557 25.6837 9.10262 25.4922 9.29418L22.5627 12.2236C22.4506 12.3357 22.2964 12.4011 22.1329 12.4011H7.30365C7.03267 12.4011 6.89718 12.0741 7.08874 11.8825L10.0181 8.95311Z"
          fill="url(#paint9_linear_2534_1238)"
        />
        <path
          d="M7.30087 12.0947L10.2278 9.16774C10.2921 9.10687 10.3723 9.07557 10.448 9.07557H25.2772C25.2784 9.07557 25.2792 9.07562 25.2798 9.07568C25.2802 9.07618 25.2806 9.0769 25.281 9.07788C25.2814 9.07884 25.2816 9.07962 25.2817 9.0802C25.2813 9.08068 25.2808 9.0813 25.28 9.08205L22.3506 12.0115C22.2943 12.0678 22.2162 12.1011 22.1329 12.1011H7.30365C7.30254 12.1011 7.30168 12.1011 7.30105 12.101C7.30072 12.1005 7.3003 12.0998 7.29989 12.0988C7.2995 12.0979 7.29928 12.0971 7.29917 12.0965C7.29956 12.096 7.30012 12.0954 7.30087 12.0947Z"
          stroke="url(#paint10_linear_2534_1238)"
          strokeOpacity="0.5"
          strokeWidth="0.6"
        />
        <path
          d="M22.1329 14.5086C22.2162 14.5086 22.2943 14.5419 22.3506 14.5982L25.28 17.5276C25.2808 17.5284 25.2813 17.529 25.2817 17.5295C25.2816 17.5301 25.2814 17.5308 25.281 17.5318C25.2806 17.5328 25.2802 17.5335 25.2798 17.534C25.2792 17.5341 25.2784 17.5341 25.2772 17.5341H10.448C10.3647 17.5341 10.2866 17.5008 10.2303 17.4444L7.30087 14.515C7.30012 14.5143 7.29956 14.5137 7.29917 14.5132C7.29928 14.5126 7.2995 14.5118 7.29989 14.5109C7.3003 14.5099 7.30071 14.5092 7.30105 14.5087C7.30168 14.5086 7.30254 14.5086 7.30365 14.5086H22.1329Z"
          fill="url(#paint11_linear_2534_1238)"
          stroke="url(#paint12_linear_2534_1238)"
          strokeWidth="0.6"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_i_2534_1238"
        x="0"
        y="0.0649414"
        width="33.969"
        height="33.969"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.90017" dy="1.90017" />
        <feGaussianBlur stdDeviation="6.17555" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2534_1238" />
      </filter>
      <filter
        id="filter1_f_2534_1238"
        x="-7.16832"
        y="-5.27797"
        width="46.6883"
        height="42.6704"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="7.02685" result="effect1_foregroundBlur_2534_1238" />
      </filter>
      <filter
        id="filter2_f_2534_1238"
        x="-34.2753"
        y="-31.6207"
        width="100.902"
        height="95.3557"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="18.8134" result="effect1_foregroundBlur_2534_1238" />
      </filter>
      <radialGradient
        id="paint0_radial_2534_1238"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16.0344 0.0649414) rotate(90) scale(20.5062)"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint1_linear_2534_1238"
        x1="23.746"
        y1="7.02533"
        x2="13.483"
        y2="26.6832"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFA3" />
        <stop offset="1" stopColor="#DC1FFF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2534_1238"
        x1="19.2584"
        y1="4.68284"
        x2="8.9954"
        y2="24.3407"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFA3" />
        <stop offset="1" stopColor="#DC1FFF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2534_1238"
        x1="21.4879"
        y1="5.84614"
        x2="11.2249"
        y2="25.504"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFA3" />
        <stop offset="1" stopColor="#DC1FFF" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_2534_1238"
        x1="26.6251"
        y1="3.58981"
        x2="12.4584"
        y2="30.7248"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFA3" />
        <stop offset="1" stopColor="#DC1FFF" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_2534_1238"
        x1="20.4308"
        y1="0.356378"
        x2="6.26405"
        y2="27.4914"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFA3" />
        <stop offset="1" stopColor="#DC1FFF" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_2534_1238"
        x1="23.5082"
        y1="1.96193"
        x2="9.34145"
        y2="29.0969"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFA3" />
        <stop offset="1" stopColor="#DC1FFF" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_2534_1238"
        x1="23.8606"
        y1="7.02523"
        x2="13.5976"
        y2="26.6831"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFA3" />
        <stop offset="1" stopColor="#DC1FFF" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_2534_1238"
        x1="6.99902"
        y1="21.5254"
        x2="25.5819"
        y2="21.5254"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DF59F3" />
        <stop offset="1" stopColor="#91B4E0" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_2534_1238"
        x1="19.3731"
        y1="4.68274"
        x2="9.11003"
        y2="24.3406"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFA3" />
        <stop offset="1" stopColor="#DC1FFF" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_2534_1238"
        x1="6.99902"
        y1="10.5884"
        x2="25.5819"
        y2="10.5884"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D4B5E8" />
        <stop offset="0.229137" stopColor="#67C9D7" />
        <stop offset="1" stopColor="#17F3C6" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_2534_1238"
        x1="21.6026"
        y1="5.84605"
        x2="11.3395"
        y2="25.5039"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFA3" />
        <stop offset="1" stopColor="#DC1FFF" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_2534_1238"
        x1="6.99902"
        y1="16.0213"
        x2="25.5819"
        y2="16.0213"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B293E9" />
        <stop offset="1" stopColor="#6EC6D8" />
      </linearGradient>
      <clipPath id="clip0_2534_1238">
        <rect width="32" height="32" rx="16" fill="white" />
      </clipPath>
      <clipPath id="clip1_2534_1238">
        <rect width="18.581" height="14.563" fill="white" transform="translate(6.88538 8.77573)" />
      </clipPath>
      <clipPath id="clip2_2534_1238">
        <rect
          width="25.6485"
          height="20.1022"
          fill="white"
          transform="translate(3.35144 6.00601)"
        />
      </clipPath>
      <clipPath id="clip3_2534_1238">
        <rect width="18.581" height="14.563" fill="white" transform="translate(7 8.77563)" />
      </clipPath>
    </defs>
  </svg>
);

export const NotFound = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="34"
    height="35"
    viewBox="0 0 34 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_i_1386_124987)">
      <rect width="34" height="34" rx="17" fill="#2C2D2C" />
      <rect
        width="34"
        height="34"
        rx="17"
        fill="url(#paint0_radial_1386_124987)"
        fillOpacity="0.15"
      />
    </g>
    <g opacity="0.2" filter="url(#filter1_f_1386_124987)">
      <circle cx="17" cy="18" r="7" fill="#D9D9D9" />
    </g>
    <path
      d="M13.85 14.15C13.85 14.15 14.2 11 17 11C19.8 11 20.15 12.75 20.15 14.15C20.15 15.2018 19.5572 16.2538 18.3717 16.7119C17.6506 16.9905 17 17.5768 17 18.35V18.7"
      stroke="url(#paint1_radial_1386_124987)"
      strokeWidth="2.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.7 22.5501C17.7 22.9366 17.3866 23.2501 17 23.2501C16.6135 23.2501 16.3 22.9366 16.3 22.5501C16.3 22.1636 16.6135 21.8501 17 21.8501C17.3866 21.8501 17.7 22.1636 17.7 22.5501Z"
      stroke="url(#paint2_radial_1386_124987)"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <filter
        id="filter0_i_1386_124987"
        x="0"
        y="0"
        width="36.0146"
        height="36.0146"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2.01459" dy="2.01459" />
        <feGaussianBlur stdDeviation="6.54743" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1386_124987" />
      </filter>
      <filter
        id="filter1_f_1386_124987"
        x="0"
        y="1"
        width="34"
        height="34"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="5" result="effect1_foregroundBlur_1386_124987" />
      </filter>
      <radialGradient
        id="paint0_radial_1386_124987"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17) rotate(90) scale(21.7411)"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_1386_124987"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16.5 12) rotate(70.71) scale(10.5948 8.66848)"
      >
        <stop stopColor="#CDCDCD" />
        <stop offset="1" stopColor="#CDCDCD" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_1386_124987"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(12 20.5) rotate(32.0054) scale(9.43398)"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);
